function SetLocation(location)
{
    if (document.getElementById("txtWhatThreeWordLocation_Set") != null && document.getElementById("txtWhatThreeWordLocation_Set") != undefined) {
        document.getElementById("txtWhatThreeWordLocation_Set").value = null;
      }
      if (document.getElementById("txtLocationGoogleApi_Set") != null && document.getElementById("txtLocationGoogleApi_Set") != undefined) {
        document.getElementById("txtLocationGoogleApi_Set").value = location;
      }
}

function MapAddressOnMapAfterLoadControl() {
    // if (GetLocalStorageData("googlemap_savedlocation") != undefined && GetLocalStorageData("googlemap_savedlocation") != null) {
        //document.getElementById("lblSavedLocationValue").innerHTML = GetLocalStorageData("googlemap_savedlocation");

    // }
    if (GetLocalStorageData("googlemap_cpsaveaddressfrommap") != undefined && GetLocalStorageData("googlemap_cpsaveaddressfrommap") != null) {

        if (GetLocalStorageData("googlemap_savedlocation") != undefined && GetLocalStorageData("googlemap_savedlocation") != null && GetLocalStorageData("googlemap_savedlocation").includes("///")) {
            MapWhatThreeWordsOnMap(GetLocalStorageData("googlemap_savedlocation").replace("///", ""), "");
        }
        else {
            geocode({ address: GetLocalStorageData("googlemap_cpsaveaddressfrommap") });
        }
        SetLocalStorageData("googlemap_cpsaveaddressfrommap",null);
    }
    else {
        var CoordinatesAddressDataToBindOnMap = "";
        var PostalAdressDataToBindOnMap = "";
        if (GetLocalStorageData("googlemap_coordinatesaddressdatatobindonmap") != undefined && GetLocalStorageData("googlemap_coordinatesaddressdatatobindonmap") != null) {
            CoordinatesAddressDataToBindOnMap = GetLocalStorageData("googlemap_coordinatesaddressdatatobindonmap");
        }
        if (GetLocalStorageData("googlemap_hdnsourceaddress") != undefined && GetLocalStorageData("googlemap_hdnsourceaddress") != null) {
            MapSourceAddress(GetLocalStorageData("googlemap_hdnsourceaddress"));

        }
        if (GetLocalStorageData("googlemap_postaladressdatatobindonmap") != undefined && GetLocalStorageData("googlemap_postaladressdatatobindonmap") != null) {
            PostalAdressDataToBindOnMap = GetLocalStorageData("googlemap_postaladressdatatobindonmap");
        }
        if (PostalAdressDataToBindOnMap != "" || CoordinatesAddressDataToBindOnMap != "") {
            MapAllAddress(CoordinatesAddressDataToBindOnMap, PostalAdressDataToBindOnMap);
        }
        else if (GetLocalStorageData("googlemap_adressdatatobindonmap") != undefined && GetLocalStorageData("googlemap_adressdatatobindonmap") != null) {
            Address = GetLocalStorageData("googlemap_adressdatatobindonmap");
            geocode({ address: Address });
        }
        else if (GetLocalStorageData("googlemap_hdndestinationaddress") != undefined && GetLocalStorageData("googlemap_hdndestinationaddress") != null) {
            MapDestinationAddress(GetLocalStorageData("googlemap_hdndestinationaddress"));

        }
        else if (GetLocalStorageData("googlemap_whatthreewordforsetmode") != undefined && GetLocalStorageData("googlemap_whatthreewordforsetmode") != null) {

            if (!GetLocalStorageData("googlemap_whatthreewordforsetmode").toString().includes("///")) {

                MapWhatThreeWordsOnMap(GetLocalStorageData("googlemap_whatthreewordforsetmode"));
            }
        }
    }
    
}


function apiToRenderMapNavigation(SourceID, Source, DestinationID, Destination, directionsService, directionsRenderer, map) {
    directionsService.route({ origin: { query: Source }, destination: { query: Destination }, travelMode: google.maps.TravelMode.DRIVING }).then((response) => {
        markers.forEach((marker) => {
            marker.setMap(null);
        });
        directionsRenderer.setDirections(response);

        IsDirectionSet = true;

        SourceID.classList.remove('borderDark');
        DestinationID.classList.remove('borderDark');

        var gotoMapButton = document.createElement("i");
        gotoMapButton.setAttribute("class", "far fa-map-marked custom_map_icon");
        gotoMapButton.setAttribute("aria-hidden", "true");
        gotoMapButton.setAttribute("title", "Navigate");
        map.controls[google.maps.ControlPosition.TOP_RIGHT].push(gotoMapButton);

        google.maps.event.addDomListener(gotoMapButton, "click", function () {
            var url = 'https://maps.apple.com/?saddr=' + Source + '&daddr=' + Destination;
            window.location = url;
        });
    }).catch((e) => 
        
        ValidationOnNavigate(SourceID, DestinationID)
        

);
}

function onChangeHandler() {
    var mapMode= map.getMapTypeId();
    map = new google.maps.Map(document.getElementById(mapDivID), {
        center: { lat: 52.504784, lng: -1.745235 },
        zoom: 6,
        mapTypeId: mapMode,
        gestureHandling: 'cooperative'
    });

    SetmapsMouseEventlistnerForWhatThreeWord(map);
    var directionsService = new google.maps.DirectionsService();
    var directionsRenderer = new google.maps.DirectionsRenderer();
    var SourceID;
    var DestinationID;
    directionsRenderer.setMap(map);
    markers = [];
    var GoogleSourceID = document.getElementById("txtLocationGoogleApi_Source");
    var GoogleDestinationID = document.getElementById("txtLocationGoogleApi_Destination");
    var WhatthreewordSourceID = document.getElementById("txtWhatThreeWordLocation_Source");
    var WhatthreewordDestinationID = document.getElementById("txtWhatThreeWordLocation_Destination");

    if (WhatthreewordSourceID != null && WhatthreewordSourceID.value.includes("///")) {
        SourceID = WhatthreewordSourceID;
    }
    else {
        SourceID = GoogleSourceID;
    }
    if (WhatthreewordDestinationID != null && WhatthreewordDestinationID.value.includes("///")) {
        DestinationID = WhatthreewordDestinationID;
    }
    else {
        DestinationID = GoogleDestinationID;
    }

    if (SourceID != null && DestinationID != null) {
        var Source = SourceID.value;
        var Destination = DestinationID.value;
        if (Destination != '' && Source != '') {
            if (Destination.includes("///") && Source.includes("///")) {
                what3words.api.convertToCoordinates(Source).then((result1) => {

                    if (result1.coordinates) {

                        what3words.api.convertToCoordinates(Destination).then((result2) => {

                            if (result2.coordinates) {
                                apiToRenderMapNavigation(SourceID, result1.coordinates.lat + ',' + result1.coordinates.lng, DestinationID, result2.coordinates.lat + ',' + result2.coordinates.lng, directionsService, directionsRenderer, map);
                            }
                        });

                    }
                });
            }
            else if (Destination.includes("///") && !Source.includes("///")) {
                what3words.api.convertToCoordinates(Destination).then((response) => {

                    if (response.coordinates) {
                        apiToRenderMapNavigation(SourceID, Source, DestinationID, response.coordinates.lat + ',' + response.coordinates.lng, directionsService, directionsRenderer, map);
                    }
                });
            }
            else if (!Destination.includes("///") && Source.includes("///")) {
                what3words.api.convertToCoordinates(Source).then((response) => {

                    if (response.coordinates) {
                        apiToRenderMapNavigation(SourceID, response.coordinates.lat + ',' + response.coordinates.lng, DestinationID, Destination, directionsService, directionsRenderer, map);
                    }
                });
            }
            else if (!Destination.includes("///") && !Source.includes("///")) {
                apiToRenderMapNavigation(SourceID, Source, DestinationID, Destination, directionsService, directionsRenderer, map);
            }



        }
        else if (Destination != '' && Source == '') {
            SourceID.classList.add('borderDark');
            DestinationID.classList.remove('borderDark');
            MapWhatThreeWordsOnMap('exchanges.motion.fame'.toString().toLowerCase(), 6);   // defaultlocation
        }
        else if (Destination == '' && Source != '') {
            SourceID.classList.remove('borderDark');
            DestinationID.classList.add('borderDark');
            MapWhatThreeWordsOnMap('exchanges.motion.fame'.toString().toLowerCase(), 6); // defaultlocation
        }
    }

}


var map;
var markers = [];
var zoom = 15;
var mapDivID;
var IsDirectionSet;
function DeclareMapVariable(isSetMode, MapID) {
    mapDivID = MapID;
    IsDirectionSet = false;
    map = new google.maps.Map(document.getElementById(MapID), {
        center: { lat: 52.504784, lng: -1.745235 },
        zoom: 6,
        mapTypeId: 'roadmap',
        gestureHandling: 'cooperative'
    });
    zoom = GetLocalStorageData("googlemap_hdnmapzoom");    

    if (isSetMode) {
        SetmapsMouseEventlistnerForWhatThreeWord(map);
    }

}

function SetmapsMouseEventlistnerForWhatThreeWord(Map) {

    Map.addListener("click", (mapsMouseEvent) => {
        ShowHideSearchBoxForMap('txtLocationGoogleApi_Set', 'txtWhatThreeWordLocation_Set', '', true);
        what3words.api.convertTo3wa(mapsMouseEvent.latLng.toJSON(), 'en').then(function (response) {
            markers.forEach((marker) => {
                marker.setMap(null);
            });

            markers = [];
            // Create a marker for the location
            var marker = new google.maps.Marker({
                position: mapsMouseEvent.latLng.toJSON(),
                map: Map,
                title: response["nearestPlace"]
            });
            markers.push(marker);
            // Center the map on that location, and zoom in on it

            var zoom = Number(GetLocalStorageData("googlemap_hdnmapzoom"));                                 
            SetLocalStorageData("googlemap_lat",mapsMouseEvent.latLng.toJSON().lat.toString());
            SetLocalStorageData("googlemap_lng",mapsMouseEvent.latLng.toJSON().lng.toString());
            Map.setCenter(mapsMouseEvent.latLng.toJSON());
            Map.setZoom(zoom);
            if (document.getElementById("txtWhatThreeWordLocation_Set") != null) {
                document.getElementById("txtWhatThreeWordLocation_Set").value = '///' + response.words;
            }
        });

    });


}



function LocationSelector(txtLocationGoogleApi, txtLocation, autosuggest, NavigateOnChangeLocation,icrosshairs) {

    this.map = map;
    this.markers = markers;

    this.txtLocationGoogleApi = txtLocationGoogleApi;
    this.txtLocation = txtLocation;
    this.autosuggest = autosuggest;
    this.NavigateOnChangeLocation = NavigateOnChangeLocation;
    if (document.getElementById(txtLocation) != undefined && document.getElementById(txtLocation) != null) {
        document.getElementById(txtLocation).addEventListener("keypress", WhatThreeWordTextboxValueChange);
        document.getElementById(txtLocation).addEventListener("onpaste", WhatThreeWordTextboxValueChange);
        document.getElementById(txtLocation).addEventListener("oninput", WhatThreeWordTextboxValueChange);
    
    }
    if (document.getElementById(txtLocationGoogleApi) != undefined && document.getElementById(txtLocationGoogleApi) != null) {
        document.getElementById(txtLocationGoogleApi).addEventListener("keypress", txtLocationGoogleApiValueChange);
        document.getElementById(txtLocationGoogleApi).addEventListener("onpaste", txtLocationGoogleApiValueChange);
        document.getElementById(txtLocationGoogleApi).addEventListener("oninput", txtLocationGoogleApiValueChange);

    }
    
    
    
    if(document.getElementById(icrosshairs) != undefined && document.getElementById(icrosshairs) != null){
        document.getElementById(icrosshairs).onclick = function(){  GetMyLocation(txtLocationGoogleApi ,txtLocation);   }
    }

    

    

    function txtLocationGoogleApiValueChange() {
        ShowHideSearchBoxForMap(txtLocationGoogleApi ,txtLocation ,'googleApicontrol');
    }
    function WhatThreeWordTextboxValueChange() {
        ShowHideSearchBoxForMap(txtLocationGoogleApi ,txtLocation ,'w3wcontrol');
    }

    this.SetAutoSuggestionForwhatthreeword = function SetAutoSuggestionForwhatthreeword(autosuggest) {
        if (autosuggest != undefined && autosuggest != null) {
            autosuggest.addEventListener("selected_suggestion", (value) => {
                // Call the what3words convert to coordinates API to obtain the latitude and longitude of the three word address provided
                what3words.api.convertToCoordinates(value.detail.suggestion.words).then((response) => {

                    if (response.coordinates) {
                        // Clear out the old markers.
                        markers.forEach((marker) => {
                            marker.setMap(null);
                        });

                        markers = [];
                        var latLng = { lat: response.coordinates.lat, lng: response.coordinates.lng };
                        SetLocalStorageData("googlemap_lat",response.coordinates.lat);
                        SetLocalStorageData("googlemap_lng",response.coordinates.lng);
                        // Create a marker for the location
                        var marker = new google.maps.Marker({
                            position: latLng,
                            map: map,
                            title: response["nearestPlace"]
                        });

                        if (NavigateOnChangeLocation != undefined && NavigateOnChangeLocation.toLowerCase() == "true") {
                            onChangeHandler();
                        }
                        else {
                            markers.push(marker);
                            // Center the map on that location, and zoom in on it

                            var zoom = Number(GetLocalStorageData("googlemap_hdnmapzoom"));
                            map.setCenter(latLng);
                            map.setZoom(zoom);
                        }
                    }
                });
            });
        }

        if (document.getElementById(autosuggest)) {
            document.getElementById(autosuggest).blur();
        }
    }

    this.SetAutoSuggestionForwhatthreeword(document.getElementById(autosuggest));

    this.SetGoogleApiUtoSuggestion = function SetGoogleApiUtoSuggestion(TextboxId) {
        var txtSearchBox = document.getElementById(TextboxId);



        var options = {
            componentRestrictions: { country: ["GB"] },

            strictBounds: false,

        };

        if (txtSearchBox != null) {
            var places = new google.maps.places.Autocomplete(txtSearchBox, options);

            google.maps.event.addListener(places, 'place_changed', function () {

                var place = places.getPlace();
                geocoder = new google.maps.Geocoder();
                SetLocalStorageData("googlemap_lat",place.geometry.location.lat());
                SetLocalStorageData("googlemap_lng",place.geometry.location.lng());
                const latlng1 = {
                    lat: parseFloat(place.geometry.location.lat()),
                    lng: parseFloat(place.geometry.location.lng()),
                };
                geocoder
                    .geocode({ location: latlng1 })
                    .then((response) => {

                        // Clear out the old markers.
                        markers.forEach((marker) => {
                            marker.setMap(null);

                        });

                        markers = [];

                        var bounds = new google.maps.LatLngBounds();
                        var latLng = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };

                        loc = new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
                        bounds.extend(loc);

                        // Create a marker for the location
                        var marker = new google.maps.Marker({
                            position: latLng,
                            map: map,
                            title: response.results[0].formatted_address

                        });

                        if (NavigateOnChangeLocation != undefined && NavigateOnChangeLocation.toLowerCase() == "true") {
                            onChangeHandler();
                        }
                        else {

                            markers.push(marker);
                            map.fitBounds(bounds);
                            map.panToBounds(bounds);
                            map.setZoom(15);
                        }


                    })
                    .catch((e) => {

                        //ShowPopup(ERROR, "Error", "Sorry!! Unable to find the address, beacuse of following reason " + e, true, false, false, false, "", "", "", "");
                    });

            });
        }

    }

    this.SetGoogleApiUtoSuggestion(this.txtLocationGoogleApi);
}

function ShowHideLocationSelectorControls(ShowTextBoxID, HideTextboxID, ValueTosShow, IsFocus = true) {
    document.getElementById(ShowTextBoxID).style.display = "unset";
    document.getElementById(HideTextboxID).style.display = "none";
    document.getElementById(ShowTextBoxID).value = ValueTosShow;
    document.getElementById(HideTextboxID).value = "";
    if (IsFocus) {
        document.getElementById(ShowTextBoxID).focus();
    }
}

function ShowHideSearchBoxForMap(googleApicontrolId, what3wordControlID, CurrentControl, ISClickEventOnMap = false) {
    var txtLocationGoogleApiclientID = googleApicontrolId;
    var txtLocationWhatThreeWordclientID = what3wordControlID;
    var fieldvalue = "";
    if (ISClickEventOnMap) {
        ShowHideLocationSelectorControls(txtLocationWhatThreeWordclientID, txtLocationGoogleApiclientID, '', false);
    }
    else {
        if (CurrentControl == "googleApicontrol") {
            fieldvalue = document.getElementById(txtLocationGoogleApiclientID).value;
        }
        else {
            fieldvalue = document.getElementById(txtLocationWhatThreeWordclientID).value;
        }
        if (document.getElementById(txtLocationWhatThreeWordclientID) != null && document.getElementById(txtLocationWhatThreeWordclientID) != undefined && document.getElementById(txtLocationGoogleApiclientID) != null && document.getElementById(txtLocationGoogleApiclientID) != undefined) {
            if (fieldvalue.includes("///")) {
                ShowHideLocationSelectorControls(txtLocationWhatThreeWordclientID, txtLocationGoogleApiclientID, fieldvalue);
            }
            else if (fieldvalue.includes("//") || fieldvalue.includes("/")) {
            }
            else {
                ShowHideLocationSelectorControls(txtLocationGoogleApiclientID, txtLocationWhatThreeWordclientID, fieldvalue);
            }
        }
    }
}

function MapWhatThreeWordsOnMap(whatThreeWord, zoom = null) {
    what3words.api.convertToCoordinates(whatThreeWord).then((response) => {

        if(!IsDirectionSet){
        if (response.coordinates) {
            // Clear out the old markers.
            markers.forEach((marker) => {
                marker.setMap(null);
            });

            markers = [];
            var latLng = { lat: response.coordinates.lat, lng: response.coordinates.lng };

            if (!(GetLocalStorageData("googlemap_whatthreewordforsetmode") != undefined && GetLocalStorageData("googlemap_whatthreewordforsetmode") != null && GetLocalStorageData("googlemap_whatthreewordforsetmode").toString().includes("///"))) {    
                SetLocalStorageData("googlemap_lat",response.coordinates.lat);
                SetLocalStorageData("googlemap_lng",response.coordinates.lng);
            }
            // Create a marker for the location
            var marker = new google.maps.Marker({
                position: latLng,
                map: map,
                title: response["nearestPlace"]
            });
            markers.push(marker);
            // Center the map on that location, and zoom in on it

            if (zoom == null || zoom == "" || zoom == undefined) {
                zoom = Number(GetLocalStorageData("googlemap_hdnmapzoom"));
            }
            map.setCenter(latLng);
            map.setZoom(zoom);

        }
    }
    });
}

function ValidationOnNavigate(SourceID, DestinationID) {
    if (SourceID.value != '' && SourceID.value != '') {
        SourceID.classList.add('borderDark'); SourceID.style.add = "color:red";
        DestinationID.classList.add('borderDark');
    }
    IsDirectionSet = false;
    MapWhatThreeWordsOnMap('exchanges.motion.fame'.toString().toLowerCase(), 6); // defaultlocation
}

function getReverseGeocodingData(lat, lng, ControlClientID) {
    var latlng = new google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'latLng': latlng }, (results, status) => {
        if (status !== google.maps.GeocoderStatus.OK) {
            alert(status);
        }
        // This is checking to see if the Geoeode Status is OK before proceeding
        if (status == google.maps.GeocoderStatus.OK) {
            var address = (results[0].formatted_address);
            if (ControlClientID != '') {


                if (document.getElementById(ControlClientID) != null) {
                    document.getElementById(ControlClientID).value = address;
                }
                if (ControlClientID.toString().toLowerCase().toString().includes("txtsourcelocationgoogleapi") || ControlClientID.toString().toLowerCase().toString().includes("txtlocationgoogleapi")) {
                    geocode({ address: address });
                    onChangeHandler();

                }
                if (ControlClientID.toString().toLowerCase().toString().includes("txtlocationgoogleapi_destination")) {
                    onChangeHandler();
                }
            }

        }
    });
}

function GetMyLocation(GetLocationControlID, WhatThreeWordControlID = '') {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
            if (position != undefined && position != null && position.coords != undefined && position.coords != null && position.coords.longitude != undefined && position.coords.longitude != null && position.coords.latitude != undefined && position.coords.latitude != null) {
                SetLocalStorageData("googlemap_lat",position.coords.latitude);
                SetLocalStorageData("googlemap_lng",position.coords.longitude);

                if (GetLocationControlID != undefined && GetLocationControlID != null && GetLocationControlID != '') {

                    ShowHideLocationSelectorControls(GetLocationControlID ,  WhatThreeWordControlID, '',false);
                    getReverseGeocodingData(position.coords.latitude, position.coords.longitude,  GetLocationControlID );
                }
                else {
                    getReverseGeocodingData(position.coords.latitude, position.coords.longitude, '');
                }
            }
        }
            ,
            function (e) {
                if (GetLocalStorageData("googlemap_whatthreewordforsetmode") != undefined && GetLocalStorageData("googlemap_whatthreewordforsetmode") != null && GetLocalStorageData("googlemap_whatthreewordforsetmode").toString().includes("///")) {
                    MapWhatThreeWordsOnMap(GetLocalStorageData("googlemap_whatthreewordforsetmode"), 6);
                }      

            },
            {
                enableHighAccuracy: true
            });
    }
    else {
        if (GetLocalStorageData("googlemap_whatthreewordforsetmode") != undefined && GetLocalStorageData("googlemap_whatthreewordforsetmode") != null && GetLocalStorageData("googlemap_whatthreewordforsetmode").toString().includes("///")) {
            MapWhatThreeWordsOnMap(GetLocalStorageData("googlemap_whatthreewordforsetmode"), 6);
        }
    }
}

function MapDestinationAddress(DestinationAddress) {
    if (document.getElementById("txtLocationGoogleApi_Destination") != null) {
        if (document.getElementById("txtLocationGoogleApi_Destination").value == undefined || document.getElementById("txtLocationGoogleApi_Destination").value == null || document.getElementById("txtLocationGoogleApi_Destination").value == '') {
            if (GetLocalStorageData("googlemap_islatlngdestinationaddress") != undefined && GetLocalStorageData("googlemap_islatlngdestinationaddress") != null && GetLocalStorageData("googlemap_islatlngdestinationaddress").toLowerCase() == "true") {
                document.getElementById("txtLocationGoogleApi_Destination").value = DestinationAddress;
                onChangeHandler();
            }
            else {
                var DestinationAddresLat = DestinationAddress.split(',')[0];
                var DestinationAddresLng = DestinationAddress.split(',')[1];
                getReverseGeocodingData(DestinationAddresLat, DestinationAddresLng, "txtLocationGoogleApi_Destination");
            }
        }
    }
}
function MapSourceAddress(SourceAddress) {
    if (document.getElementById("txtLocationGoogleApi_Source") != null) {
        if (document.getElementById("txtLocationGoogleApi_Source").value == undefined || document.getElementById("txtLocationGoogleApi_Destination").value == null || document.getElementById("txtLocationGoogleApi_Destination").value == '') {
            if (GetLocalStorageData("googlemap_islatlngsourceaddress") != undefined && GetLocalStorageData("googlemap_islatlngsourceaddress") != null && GetLocalStorageData("googlemap_islatlngsourceaddress").toLowerCase() == "true") {
                document.getElementById("txtLocationGoogleApi_Source").value = SourceAddress;
                onChangeHandler();
            }
        }
    }
}

function geocode(request) {
    // Clear out the old markers.
    markers.forEach((marker) => { marker.setMap(null); });
    if (request.address == undefined || request.address.toString() == '') {
        if (document.getElementById("txtLocationGoogleApi_Set") != null) {
            document.getElementById("txtLocationGoogleApi_Set").value = "";
        }
    }
    else {

        //var zoom = Number('<%= MapZoom %>');
        zoom = Number(zoom);
        geocoder = new google.maps.Geocoder();
        var marker = new google.maps.Marker({
            map: map,
        });

        geocoder
            .geocode(request)
            .then((result) => {
                const { results } = result;
                map.setCenter(results[0].geometry.location);
                map.setZoom(zoom);
                marker.setPosition(results[0].geometry.location);
                marker.setMap(map);
                markers.push(marker);
                return results;
            })
            .catch((e) => {

                //ShowPopup(ERROR, "Error", "Sorry!! Unable to find the address. Please try with a valid address.", true, false, false, false, "", "", "", "");
            });

    }
}

function MapAllAddress(AddressCoordinatesString, AddressPostalCountryCodeString) {

    var geocoder = new google.maps.Geocoder();

    geocoder = new google.maps.Geocoder();
    markers.forEach((marker) => { marker.setMap(null); });
    markers = [];
    var bounds = new google.maps.LatLngBounds();
    var latLng1;
    for (let i = 0; i < AddressCoordinatesString.split("|").length - 1; i++) {
        latLng1 = { lat: Number(AddressCoordinatesString.split("|")[i].split(",")[0]), lng: Number(AddressCoordinatesString.split("|")[i].split(",")[1]) };
        loc = new google.maps.LatLng(AddressCoordinatesString.split("|")[i].split(",")[0], AddressCoordinatesString.split("|")[i].split(",")[1]);
        bounds.extend(loc);

        markers.push(new google.maps.Marker({
            position: { lat: Number(AddressCoordinatesString.split("|")[i].split(",")[0]), lng: Number(AddressCoordinatesString.split("|")[i].split(",")[1]) },
            map: map

        }));

        if (GetLocalStorageData("googlemap_isautozoom") != undefined && GetLocalStorageData("googlemap_isautozoom") != null && GetLocalStorageData("googlemap_isautozoom").toString() == "False") {
            map.setCenter(latLng1);
            map.setZoom(Number(GetLocalStorageData("googlemap_hdnmapzoom")));
        }
        else {
            map.fitBounds(bounds);
            map.panToBounds(bounds);
        }

    }


    for (let i = 0; i < AddressPostalCountryCodeString.split("|").length - 1; i++) {
        var address = AddressPostalCountryCodeString.split("|")[i];

        geocoder.geocode({ 'address': address }, function (results, status) {

            if (status == google.maps.GeocoderStatus.OK) {
                latLng1 = { lat: Number(results[0].geometry.location.lat()), lng: Number(results[0].geometry.location.lng()) };

                loc = new google.maps.LatLng(results[0].geometry.location.lat().toString(), results[0].geometry.location.lng().toString());
                bounds.extend(loc);

                markers.push(new google.maps.Marker({
                    position: { lat: Number(results[0].geometry.location.lat()), lng: Number(results[0].geometry.location.lng()) },
                    map: map,
                    title: results[0].formatted_address

                }));

                if (GetLocalStorageData("googlemap_isautozoom") != undefined && GetLocalStorageData("googlemap_isautozoom") != null && GetLocalStorageData("googlemap_isautozoom").toString() == "False") {
                    map.setCenter(latLng1);
                    map.setZoom(Number(GetLocalStorageData("googlemap_hdnmapzoom")));
                }
                else {
                    map.fitBounds(bounds);
                    map.panToBounds(bounds);
                }

            }

        });

    }

    if (GetLocalStorageData("googlemap_isautozoom") != undefined && GetLocalStorageData("googlemap_isautozoom") != null && GetLocalStorageData("googlemap_isautozoom").toString() == "False") {

    }
    else {
        map.fitBounds(bounds);
        map.panToBounds(bounds);
    }

}


function CreateLocationSelectorObject(txtLocationGoogleApi, txtWhatThreeWordLocation, AutoSuggestID, isNavigateOnChangeLocation, icrosshairs) {
    var MyCls = new LocationSelector(txtLocationGoogleApi, txtWhatThreeWordLocation, AutoSuggestID, isNavigateOnChangeLocation,icrosshairs);
}

function GetLocalStorageData(key)
{
  return JSON.parse(localStorage.getItem(key));
}

function SetLocalStorageData(key,value)
{
    localStorage.setItem(key,JSON.stringify(value));
}